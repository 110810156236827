.banner {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.375rem;
  user-select: none;

  font-weight: 700;
  line-height: 1.1;
  text-transform: uppercase;

  background-image: linear-gradient(
    to bottom,
    var(--primary-light),
    var(--primary-dark)
  );

  box-shadow:
    0 2px 10px 0 rgba(0, 0, 0, 0.05),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.25),
    inset 0 0px 40px 0 rgba(255, 255, 255, 0.2);
}

.image {
  margin-right: 0.5rem;
  object-fit: cover;
  box-shadow: 0 1px 3px 2px rgba(0, 0, 0, 0.18);
  border-radius: 100px;
}

.content {
  @apply flex w-full flex-col justify-center gap-0.5 px-2 text-left;
}

.description {
  @apply text-white text-opacity-50;
}

.append {
  @apply flex flex-1 items-center justify-end pl-3 text-white text-opacity-50;
}
